import React, { useState } from 'react';
import {
    Container, Typography, Button,
    Modal, Box, TextField, Grid, useMediaQuery, useTheme
} from '@mui/material';
import Sites from './sites/Sites';
import apiWrapper from "../../apiConfig";
import useAuthStore from "../../store/authStore";

const Home = () => {
    const { token } = useAuthStore();
    const [error, setError] = useState('');
    const [open, setOpen] = useState(false);
    const [telegramUsername, setTelegramUsername] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [checkMessage, setCheckMessage] = useState('');

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleTelegramUpdate = async () => {
        try {
            const response = await apiWrapper.put(`/api/update_telegram`,
                { telegram: telegramUsername },
                {
                    headers: { Authorization: token }
                }
            );
            if (response.data.changes > 0) {
                setSuccessMessage('Telegram username updated successfully. Please run /reg in the Telegram bot.');
                handleClose();
            }
        } catch (error) {
            setError('Failed to update Telegram username');
        }
    };

    const handleCheckDomains = async () => {
        try {
            const response = await apiWrapper.post(`/api/trigger_background_task`, {}, {
                headers: { Authorization: token }
            });
            setCheckMessage(response.data.message);
        } catch (error) {
            setError('Failed to trigger domain check');
        }
    };

    const handleDisableTls13 = async () => {
        try {
            const response = await apiWrapper.post(`/api/update-all-domains-tls`, { state: 'off' }, {
                headers: { Authorization: token }
            });
            setCheckMessage(response.data.message);
        } catch (error) {
            setError('Failed to trigger Batch Disable TLS 1.3');
        }
    };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));  // Check if the screen size is small

    return (
        <Container maxWidth="lg">
            {error && <Typography color="error">{error}</Typography>}
            {successMessage && <Typography color="primary">{successMessage}</Typography>}
            {checkMessage && <Typography color="primary">{checkMessage}</Typography>}

            {/* Использование Grid для расположения кнопок */}
            <Grid container spacing={2} direction={isMobile ? 'column' : 'row'}>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={handleOpen} fullWidth={!isMobile}>
                        Add Telegram Username
                    </Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="secondary" onClick={handleCheckDomains} fullWidth={!isMobile}>
                        Check Domains
                    </Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="warning" onClick={handleDisableTls13} fullWidth={!isMobile}>
                        Disable All Tls1.3
                    </Button>
                </Grid>
            </Grid>

            {/* Доменные компоненты */}
            <Sites
                status="blocked"
                label="Заблокированные домены"
            />
            <Sites
                status="broken"
                label="Проблемные домены"
                open={true}
            />
            <Sites
                status="expired"
                label="Истекающие домены"
                open={true}
            />
            <Sites
                status="all"
                label="Все домены"
                open={false}
            />

            {/* Модальное окно для добавления Telegram username */}
            <Modal open={open} onClose={handleClose}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: isMobile ? '90%' : 400,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography variant="h6" component="h2">
                        Add Telegram Username
                    </Typography>
                    <TextField
                        label="Telegram Username"
                        fullWidth
                        value={telegramUsername}
                        onChange={(e) => setTelegramUsername(e.target.value)}
                        margin="normal"
                    />
                    <Button variant="contained" color="primary" onClick={handleTelegramUpdate} fullWidth>
                        Submit
                    </Button>
                </Box>
            </Modal>
        </Container>
    );
};

export default Home;