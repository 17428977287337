import React, {useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/pages/login/Login';
import Dashboard from './components/pages/Dashboard';
import Admin from './components/pages/admin/Admin';
import Home from './components/pages/Home';
import Cloudflare from './components/pages/cloudflare/Cloudflare';
import CloudflareGroups from './components/pages/cloudflare-groups/CloudflareGroups';
import Servers from './components/pages/servers/Servers';
import ChangeDNS from './components/pages/dns/ChangeDNS';
import TableManager from './components/pages/tables/TableManager';
import Sites from "./components/pages/sites/Sites";
import CheckSites from "./components/pages/check-site/CheckSites";
import Projects from "./components/pages/projects/Projects";
import Registrars from "./components/pages/registrars/Registrars";
import useAuthStore from './store/authStore';
import XmlRiver from "./components/pages/xmlriver/XmlRiver";
import Position from "./components/pages/position/Position";
import Templates from "./components/pages/templates/Templates";

const App = () => {
  const { token, user, initializeAuth } = useAuthStore();

  useEffect(() => {
    initializeAuth();
  }, []);

  return (
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={token ? <Dashboard /> : <Navigate to="/login" />}>
            <Route index element={<Home />} />
            <Route path="projects" element={<Projects />} />
            <Route path="position" element={<Position />} />
            <Route path="sites" element={<Sites />} />
            <Route path="check-site" element={<CheckSites />} />
            <Route path="cloudflare" element={<Cloudflare />} />
            <Route path="cloudflare-groups" element={<CloudflareGroups />} />
            <Route path="servers" element={<Servers />} />
            <Route path="dns" element={<ChangeDNS />} />
            <Route path="registrars" element={<Registrars />} />
            <Route path="xmlriver" element={<XmlRiver />} />
            <Route path="templates" element={<Templates />} />
            {user?.role === 'admin' && (
                <Route path="admin" element={<Admin />} />
            )}
            {user?.role === 'admin' && (
                <Route path="tables" element={<TableManager />} />
            )}
          </Route>
        </Routes>
      </Router>
  );
};

export default App;
