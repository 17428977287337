const { toASCII } = require('punycode');

class Helper {
    static validateAndCleanUrl = (url) => {
        try {
            // Удаляем протокол (http://, https://) и слеш в конце
            const cleanUrl = url.replace(/^https?:\/\//, '').replace(/\/$/, '');

            // Преобразуем в Punycode (если домен кириллический)
            const punycodeUrl = toASCII(cleanUrl);

            // Проверяем, что это валидный домен
            const domainRegex = /^[a-zA-Z0-9.-]+$/;
            if (!domainRegex.test(punycodeUrl)) {
                throw new Error(`Некорректный URL: ${url}`);
            }

            return punycodeUrl; // Возвращаем в формате Punycode
        } catch (error) {
            throw new Error(`Ошибка проверки URL: ${error.message}`);
        }
    };

    /**
     * Валидирует домен по следующим правилам:
     * - Длина от 1 до 63 символов (без учёта TLD)
     * - Может содержать только буквы, цифры и дефис
     * - Не может начинаться или заканчиваться дефисом
     * - Правильный TLD (.com, .net и т.д.)
     * @param {string} domain - Домен для проверки
     * @returns {{isValid: boolean, errors: string[]}} - Результат валидации
     */
    static validateDomain(domain) {
        const errors = [];

        // Проверяем на пустоту
        if (!domain) {
            return { isValid: false, errors: ['Домен не может быть пустым'] };
        }

        // Разделяем домен на имя и TLD
        const parts = domain.split('.');
        if (parts.length < 2) {
            errors.push('Домен должен содержать TLD (например, .com)');
            return { isValid: false, errors };
        }

        const domainName = parts.slice(0, -1).join('.');
        // const tld = parts[parts.length - 1];

        // Проверка длины домена (без TLD)
        if (domainName.length < 1 || domainName.length > 63) {
            errors.push('Длина домена должна быть от 1 до 63 символов (без учёта TLD)');
        }

        // Проверка на допустимые символы
        if (!/^[a-zA-Z0-9-]+$/.test(domainName)) {
            errors.push('Домен может содержать только буквы, цифры и дефис');
        }

        // Проверка на дефисы в начале и конце
        if (domainName.startsWith('-') || domainName.endsWith('-')) {
            errors.push('Домен не может начинаться или заканчиваться дефисом');
        }

        // Проверка TLD
        // const validTlds = ['com', 'net', 'org', 'info', 'biz', 'ru', 'рф'];
        // if (!validTlds.includes(tld.toLowerCase())) {
        //     errors.push(`Недопустимый TLD. Разрешённые: ${validTlds.join(', ')}`);
        // }

        return {
            isValid: errors.length === 0,
            errors
        };
    }

    /**
     * Генерирует домен из шаблона
     * Поддерживает следующие конструкции:
     * {a|b|c} - перебор вариантов
     * [a|b|c] - перестановки
     * [ +разделитель+a|b|c ] - перестановки с разделителем
     * @param {string} template - Шаблон домена
     * @returns {string} - Массив сгенерированных доменов
     */
    static generateDomainsFromTemplate(template) {
        const TEMPLATE_PATTERNS = {
            CHOICE: /{([^{}]+)}/,
            PERMUTATION: /\[(([^[\]]+))\]/,
            SEPARATOR: /\s*\+(.+?)\+/
        };

        const processOptions = (options, separator = '') => {
            const trimmedOptions = options.map(o => o.trim());
            return separator
                ? shuffleArray(trimmedOptions).join(separator)
                : getRandomElement(trimmedOptions);
        };

        const processChoiceTemplate = (domain) => {
            const match = domain.match(TEMPLATE_PATTERNS.CHOICE);
            if (!match) return domain;

            const options = match[1].split('|');
            return domain.replace(match[0], processOptions(options));
        };

        const processPermutationTemplate = (domain) => {
            const match = domain.match(TEMPLATE_PATTERNS.PERMUTATION);
            if (!match) return domain;

            const separatorMatch = match[1].match(TEMPLATE_PATTERNS.SEPARATOR);
            let options;
            let separator = '';

            if (separatorMatch) {
                separator = separatorMatch[1];
                options = match[1]
                    .replace(separatorMatch[0], '')
                    .split('|');
            } else {
                options = match[1].split('|');
            }

            return domain.replace(match[0], processOptions(options, separator));
        };

        const getRandomElement = (arr) => arr[Math.floor(Math.random() * arr.length)];

        const shuffleArray = (arr) => [...arr].sort(() => Math.random() - 0.5);

        let result = template;
        while (result.includes('{')) {
            result = processChoiceTemplate(result);
        }
        while (result.includes('[')) {
            result = processPermutationTemplate(result);
        }

        return result;
    }

    /**
     * Валидирует шаблон домена
     * @param {string} template - Шаблон для проверки
     * @returns {{
     *   isValid: boolean,
     *   errors: string[],
     *   warnings: string[],
     *   estimatedCombinations: number
     * }}
     */
    static validateTemplate(template) {
        const errors = [];
        const warnings = [];
        let estimatedCombinations = 1;

        // Проверка на пустой шаблон
        if (!template?.trim()) {
            return {
                isValid: false,
                errors: ['Шаблон не может быть пустым'],
                warnings: [],
                estimatedCombinations: 0
            };
        }

        // Проверка на наличие TLD
        if (!template.includes('.')) {
            errors.push('Шаблон должен содержать TLD (например, .com)');
        }

        // Проверка на балансировку фигурных скобок
        const unmatchedCurly = (template.match(/{/g) || []).length !== (template.match(/}/g) || []).length;
        if (unmatchedCurly) {
            errors.push('Несбалансированные фигурные скобки');
        }

        // Проверка на балансировку квадратных скобок
        const unmatchedSquare = (template.match(/\[/g) || []).length !== (template.match(/\]/g) || []).length;
        if (unmatchedSquare) {
            errors.push('Несбалансированные квадратные скобки');
        }

        // Проверка содержимого фигурных скобок
        const curlyBrackets = template.match(/{[^{}]*}/g) || [];
        curlyBrackets.forEach(bracket => {
            const content = bracket.slice(1, -1).trim();
            const options = content.split('|').map(opt => opt.trim());
            if (options.length < 2 || options.some(opt => !opt)) {
                errors.push(`Перебор "${bracket}" должен содержать хотя бы два непустых варианта, разделенных |`);
            } else {
                estimatedCombinations *= options.length;
            }
        });

        // Проверка содержимого квадратных скобок
        const squareBrackets = template.match(/\[[^\[\]]*\]/g) || [];
        squareBrackets.forEach(bracket => {
            const content = bracket.slice(1, -1).trim();
            const separatorMatch = content.match(/\s*\+(.+?)\+/);
            const actualContent = separatorMatch
                ? content.replace(separatorMatch[0], '').trim()
                : content;
            const options = actualContent.split('|').map(opt => opt.trim());
            if (options.length < 2 || options.some(opt => !opt)) {
                errors.push(`Перестановка "${bracket}" должна содержать хотя бы два непустых варианта, разделенных |`);
            } else {
                estimatedCombinations *= options.length;
            }
        });

        // Проверка на вложенные конструкции
        if (/{[^{}]*{[^{}]*}[^{}]*}/.test(template) || /\[[^\[\]]*\[[^\[\]]*\][^\[\]]*\]/.test(template)) {
            errors.push('Вложенные конструкции не поддерживаются');
        }

        // Проверка на превышение лимита комбинаций
        const COMBINATIONS_WARNING_THRESHOLD = 1000;
        if (estimatedCombinations > COMBINATIONS_WARNING_THRESHOLD) {
            warnings.push(`Шаблон сгенерирует ${estimatedCombinations} комбинаций, что может быть избыточным`);
        }

        // Проверка длины базового шаблона
        const baseLength = template
            .replace(/{[^{}]*}/g, 'x')
            .replace(/\[[^\[\]]*\]/g, 'x')
            .length;
        if (baseLength > 63) {
            errors.push('Базовая длина шаблона превышает 63 символа');
        }

        // Проверка на последовательные дефисы
        if (/--/.test(template)) {
            warnings.push('Шаблон может генерировать домены с последовательными дефисами');
        }

        // Проверка начала и конца на дефис
        const baseDomain = template.split('.')[0];
        if (baseDomain.startsWith('-') || baseDomain.endsWith('-')) {
            errors.push('Домен не может начинаться или заканчиваться дефисом');
        }

        return {
            isValid: errors.length === 0,
            errors,
            warnings,
            estimatedCombinations
        };
    }


}

module.exports = Helper;