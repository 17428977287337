import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Snackbar,
  Alert,
  TextField,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import apiWrapper from '../../../apiConfig';
import useAuthStore from "../../../store/authStore";

const ChangeDNS = ({ initialDomain }) => {
  const { token } = useAuthStore();
  const [cloudflareAccounts, setCloudflareAccounts] = useState([]);
  const [servers, setServers] = useState([]);
  const [domains, setDomains] = useState([]);
  const [filteredServers, setFilteredServers] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState('');
  const [selectedServer, setSelectedServer] = useState('');
  const [tls, setTls] = useState('off');
  const [updateNs, setUpdateNs] = useState('off');
  const [selectedDomain, setSelectedDomain] = useState(initialDomain || '');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    fetchCloudflareAccounts();
    fetchServers();
    fetchDomains();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (domains.length > 0 && initialDomain) {
      handleDomainChange(initialDomain, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domains, initialDomain]);

  const fetchCloudflareAccounts = async () => {
    try {
      const response = await apiWrapper.get(`/api/cloudflare_accounts`, {
        headers: { Authorization: token },
      });
      setCloudflareAccounts(response.data.data);
    } catch (error) {
      setError('Failed to fetch Cloudflare accounts');
      setOpenSnackbar(true);
    }
  };

  const fetchServers = async () => {
    try {
      const response = await apiWrapper.get(`/api/servers`, {
        headers: { Authorization: token },
      });
      setServers(response.data.data);
    } catch (error) {
      setError('Failed to fetch servers');
      setOpenSnackbar(true);
    }
  };

  const fetchDomains = async () => {
    try {
      const response = await apiWrapper.get(`/api/domains`, {
        headers: { Authorization: token },
      });
      setDomains(response.data.data);
    } catch (error) {
      setError('Failed to fetch domains');
      setOpenSnackbar(true);
    }
  };

  const handleDomainChange = (value, preSelect = false) => {
    const selectedDomainName = preSelect ? value : value.trim();
    setSelectedDomain(selectedDomainName);

    const existingDomain = domains.find(
        (domain) => domain.domain_name.toLowerCase() === selectedDomainName.toLowerCase()
    );

    if (existingDomain) {
      // Domain exists in the list; filter servers accordingly
      const filtered = servers.filter((server) => server.id === existingDomain.server_id);
      setFilteredServers(filtered);
      setSelectedServer(existingDomain.server_id || '');
    } else {
      // Domain is entered manually; allow any server
      setFilteredServers(servers);
      setSelectedServer('');
    }
  };

  const handleChangeDNS = async () => {
    // Validation checks
    if (!selectedAccount) {
      setError('Please select a Cloudflare account.');
      setOpenSnackbar(true);
      return;
    }

    if (!selectedDomain) {
      setError('Please enter a domain name.');
      setOpenSnackbar(true);
      return;
    }

    if (!selectedServer) {
      setError('Please select a server.');
      setOpenSnackbar(true);
      return;
    }

    setLoading(true);
    setMessage('');
    setError('');

    try {
      const server = servers.find((s) => s.id === selectedServer);
      if (!server) {
        throw new Error('Selected server not found.');
      }

      const payload = {
        accountId: selectedAccount,
        domainName: selectedDomain,
        ipAddress: server.ssh_host,
        tls: tls,
        updateNs: updateNs,
      };

      const response = await apiWrapper.post(`/api/manage_dns`, payload, {
        headers: { Authorization: token },
      });

      setLoading(false);
      setMessage(response.data.message || 'DNS changed successfully.');
      setOpenSnackbar(true);
    } catch (error) {
      setLoading(false);
      setError(
          error.response?.data?.message || 'Failed to change DNS. Please try again later.'
      );
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
      <Container>
        <Typography variant="h5" align="center" gutterBottom>
          Change DNS
        </Typography>
        <Box display="flex" flexDirection="column" maxWidth="500px" margin="0 auto">
          <Autocomplete
              options={cloudflareAccounts}
              getOptionLabel={(option) => option.name}
              value={cloudflareAccounts.find((acc) => acc.id === selectedAccount) || null}
              onChange={(event, newValue) => {
                setSelectedAccount(newValue ? newValue.id : '');
              }}
              renderInput={(params) => (
                  <TextField
                      {...params}
                      label="Cloudflare Account"
                      margin="normal"
                      fullWidth
                  />
              )}
          />

          <Autocomplete
              freeSolo
              options={domains.map((domain) => domain.domain_name)}
              value={selectedDomain}
              onChange={(event, newValue) => {
                handleDomainChange(newValue || '');
              }}
              onInputChange={(event, newInputValue) => {
                if (event && event.type === 'change') {
                  handleDomainChange(newInputValue || '');
                }
              }}
              disabled={!!initialDomain}
              renderInput={(params) => (
                  <TextField
                      {...params}
                      label="Domain"
                      margin="normal"
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                      }}
                  />
              )}
          />

          <FormControl margin="normal" fullWidth>
            <InputLabel id="server-label">Server</InputLabel>
            <Select
                labelId="server-label"
                value={selectedServer}
                onChange={(e) => setSelectedServer(e.target.value)}
                label="Server"
                disabled={!selectedDomain}
                variant={'outlined'}
            >
              {filteredServers.map((server) => (
                  <MenuItem key={server.id} value={server.id}>
                    {server.name}
                  </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl margin="normal" fullWidth>
            <InputLabel id="tls-label">TLS 1.3</InputLabel>
            <Select
                labelId="tls-label"
                value={tls}
                onChange={(e) => setTls(e.target.value)}
                label="TLS 1.3"
                variant={'outlined'}
            >
              <MenuItem value="off">Disable</MenuItem>
              <MenuItem value="on">Enable</MenuItem>
            </Select>
          </FormControl>

          <FormControl margin="normal" fullWidth>
            <InputLabel id="ns-label">Обновить NS сервера</InputLabel>
            <Select
                labelId="ns-label"
                value={updateNs}
                onChange={(e) => setUpdateNs(e.target.value)}
                label="Обновить NS сервера"
                variant={'outlined'}
            >
              <MenuItem value="on">Да</MenuItem>
              <MenuItem value="off">Нет</MenuItem>
            </Select>
          </FormControl>

          <Button
              variant="contained"
              color="primary"
              onClick={handleChangeDNS}
              margin="normal"
              disabled={loading}
              fullWidth
              style={{ marginTop: '16px' }}
          >
            {loading ? <CircularProgress size={24} /> : 'Change DNS'}
          </Button>
        </Box>

        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert
              onClose={handleCloseSnackbar}
              severity={error ? 'error' : 'success'}
              sx={{ width: '100%' }}
          >
            {error || message}
          </Alert>
        </Snackbar>
      </Container>
  );
};

export default ChangeDNS;
