import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    CircularProgress,
    Collapse
} from '@mui/material';
import {
    Add as AddIcon,
    KeyboardArrowDown as ExpandIcon,
    KeyboardArrowUp as CollapseIcon
} from '@mui/icons-material';
import useErrorStore from "../../../store/errorStore";
import useAuthStore from "../../../store/authStore";
import apiWrapper from "../../../apiConfig";
import DeletePopover from "../../ui/DeletePopover";
import PositionDataComponent from "./PositionTable";

const PositionData = ({ projectId }) => {
    const { token } = useAuthStore();
    const setSnackbar = useErrorStore(state => state.setSnackbar);

    // Состояния
    const [keywords, setKeywords] = useState([]);
    const [loading, setLoading] = useState(true);
    const [newKeywords, setNewKeywords] = useState('');
    const [openRows, setOpenRows] = useState({});

    // Загрузка ключевых слов при монтировании или изменении projectId
    useEffect(() => {
        fetchKeywords();
        setOpenRows([]);
    }, [projectId]);

    // Получение ключевых слов с бэкенда
    const fetchKeywords = async () => {
        try {
            setLoading(true);
            const response = await apiWrapper.get(`/api/keywords/${projectId}`, {
                headers: { Authorization: token }
            });

            setKeywords(response.data);
            setLoading(false);
        } catch (err) {
            setSnackbar('Произошла ошибка при загрузке ключевых слов', 'error');
            setLoading(false);
            console.error('Ошибка загрузки ключевых слов:', err);
        }
    };

    // Удаление ключевого слова
    const handleDeleteKeyword = async (keywordId) => {
        try {
            await apiWrapper.delete(`/api/keywords/${keywordId}`, {
                headers: { Authorization: token }
            });

            setSnackbar('Ключевое слово успешно удалено', 'success');
            setKeywords(keywords.filter(keyword => keyword.id !== keywordId));
        } catch (err) {
            setSnackbar('Произошла ошибка при удалении ключевого слова', 'error');
            console.error('Ошибка удаления ключевого слова:', err);
        }
    };

    // Функционал редактирования ключевых слов удален, так как нет соответствующего API эндпоинта

    // Добавление новых ключевых слов
    const handleAddKeywords = async () => {
        if (!newKeywords.trim()) return;

        // Разделяем введенный текст на строки
        const keywordsArray = newKeywords
            .split('\n')
            .map(keyword => keyword.trim())
            .filter(keyword => keyword.length > 0);

        try {
            // Добавляем каждое ключевое слово по отдельности
            for (const keyword of keywordsArray) {
                await apiWrapper.post('/api/keywords', {
                    keyword: keyword,
                    project_id: projectId
                }, {
                    headers: { Authorization: token }
                });
            }

            // Обновляем список ключевых слов
            await fetchKeywords();

            setNewKeywords('');
            setSnackbar('Ключевые слова успешно добавлены', 'success');
        } catch (err) {
            setSnackbar('Произошла ошибка при добавлении ключевых слов', 'error');
            console.error('Ошибка добавления ключевых слов:', err);
        }
    };

    // Переключение состояния раскрытия строки
    const toggleRowExpand = (keywordId) => {
        setOpenRows(prev => ({
            ...prev,
            [keywordId]: !prev[keywordId]
        }));
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', padding: 4 }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box flex={1} sx={{ width: '100%', maxWidth: '100%' }}>
            <Typography variant="h6" sx={{ mb: 2 }}>Ключевые слова проекта</Typography>

            {/* Таблица с ключевыми словами */}
            <TableContainer component={Paper} sx={{
                mb: 3,
                width: '100%'
            }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell width="50px"></TableCell>
                            <TableCell>Ключевое слово</TableCell>
                            <TableCell align="right">Действия</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {keywords.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={3} align="center">
                                    Нет добавленных ключевых слов
                                </TableCell>
                            </TableRow>
                        ) : (
                            keywords.map((keyword) => (
                                <React.Fragment key={keyword.id}>
                                    <TableRow>
                                        <TableCell>
                                            <IconButton
                                                aria-label="развернуть строку"
                                                size="small"
                                                onClick={() => toggleRowExpand(keyword.id)}
                                            >
                                                {openRows[keyword.id] ? <CollapseIcon /> : <ExpandIcon />}
                                            </IconButton>
                                        </TableCell>
                                        <TableCell>
                                            {keyword.value}
                                        </TableCell>
                                        <TableCell align="right">
                                            <DeletePopover
                                                onDelete={() => handleDeleteKeyword(keyword.id)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                            <Collapse in={openRows[keyword.id] || false} timeout="auto" unmountOnExit>
                                                <Box sx={{ margin: 1 }}>
                                                    <Typography variant="h6" gutterBottom component="div">
                                                        Позиции для: {keyword.value}
                                                    </Typography>
                                                    <PositionDataComponent
                                                        projectId={projectId}
                                                        keyword={keyword}
                                                    />
                                                </Box>
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Форма для добавления новых ключевых слов */}
            <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                    Добавить ключевые слова
                </Typography>
                <TextField
                    fullWidth
                    multiline
                    rows={3}
                    placeholder="Введите ключевые слова (каждое с новой строки)"
                    value={newKeywords}
                    onChange={(e) => setNewKeywords(e.target.value)}
                    sx={{ mb: 1 }}
                />
                <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={handleAddKeywords}
                >
                    Добавить
                </Button>
            </Box>
        </Box>
    );
};

export default PositionData;
