import React from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Button,
} from "@mui/material";
import apiWrapper from "../../apiConfig";
import useAuthStore from "../../store/authStore";

const RegistrarFormDialog = ({ open, isEditing, registrarData, onClose, fetchRegistrars }) => {
    const { token } = useAuthStore();
    const [formData, setFormData] = React.useState(registrarData);

    React.useEffect(() => {
        setFormData(registrarData);
    }, [registrarData]);

    const handleChange = (field, value) => {
        setFormData((prev) => ({ ...prev, [field]: value }));
    };

    const handleSubmit = async () => {
        try {
            if (isEditing) {
                await apiWrapper.put(`/api/registrars/${formData.id}`, formData, {
                    headers: { Authorization: token },
                });
            } else {
                await apiWrapper.post(`/api/registrars`, formData, {
                    headers: { Authorization: token },
                });
            }
            fetchRegistrars();
            setFormData({
                type: "reghouse.ru",
                name: "",
                login: "",
                password: "",
            })
            onClose();
        } catch {
            alert("Ошибка сохранения регистратора");
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{isEditing ? "Редактировать регистратора" : "Добавить регистратора"}</DialogTitle>
            <DialogContent>
                <DialogContentText>Заполните данные регистратора:</DialogContentText>
                <FormControl margin="normal" fullWidth>
                    <InputLabel>Тип</InputLabel>
                    <Select
                        value={formData.type}
                        onChange={(e) => handleChange("type", e.target.value)}
                        disabled={isEditing}
                        variant={'outlined'}
                    >
                        <MenuItem value="reghouse.ru">reghouse.ru</MenuItem>
                        <MenuItem value="expired.ru">expired.ru</MenuItem>
                        <MenuItem value="godaddy.com">godaddy.com</MenuItem>
                        <MenuItem value="namecheap.com">namecheap.com</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    fullWidth
                    margin="normal"
                    label="Имя"
                    value={formData.name}
                    onChange={(e) => handleChange("name", e.target.value)}
                />
                <TextField
                    fullWidth
                    margin="normal"
                    label="Логин/Key"
                    value={formData.login}
                    onChange={(e) => handleChange("login", e.target.value)}
                />
                <TextField
                    fullWidth
                    margin="normal"
                    label="Пароль/Secret"
                    value={formData.password}
                    onChange={(e) => handleChange("password", e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Отмена</Button>
                <Button onClick={handleSubmit} color="primary">
                    {isEditing ? "Сохранить" : "Добавить"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RegistrarFormDialog;