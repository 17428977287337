import { create } from 'zustand';
import { enqueueSnackbar, closeSnackbar } from 'notistack';

const useErrorStore = create((set) => ({
    // Добавление новой ошибки
    setSnackbar: (message, variant = 'info', duration = 5000) => {
        enqueueSnackbar(message, {
            variant: variant,
            preventDuplicate: true,
            autoHideDuration: duration,
            action: (snackbarId) => (
                <button
                    onClick={() => closeSnackbar(snackbarId)}
                    style={{ color: '#fff', background: 'none', border: 'none', cursor: 'pointer' }}
                >
                    ✖
                </button>
            )
        });
    },

    // Очистка всех уведомлений
    clearAllErrors: () => {
        closeSnackbar();
    }
}));

export default useErrorStore;