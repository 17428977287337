import React, {useState, useEffect} from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    Typography,
    Box,
    Chip
} from '@mui/material';
import apiWrapper from '../../../apiConfig';
import useAuthStore from "../../../store/authStore";
const _ = require('lodash');

const CloudflareGroupsForm = ({open, onClose, group}) => {
    const { token } = useAuthStore();
    const [groupName, setGroupName] = useState('');
    const [selectedAccounts, setCloudflareAccounts] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [error, setError] = useState('');

    const fetchCloudflareAccounts = async () => {
        try {
            const response = await apiWrapper.get(`/api/cloudflare_accounts`, {
                headers: {Authorization: token},
            });
            setAccounts(response.data.data);
        } catch (ex) {
            console.error("Failed to fetch Cloudflare accounts:", ex);
            setError('Failed to fetch Cloudflare accounts');
        }
    };

    useEffect(() => {
        fetchCloudflareAccounts()
    }, []);

    useEffect(() => {
        if (group) {
            setGroupName(group.name);
            setCloudflareAccounts(group.accounts.map(acc => acc.id) || []);
        } else {
            setGroupName('');
            setCloudflareAccounts([]);
        }
    }, [group]);

    const onCloseModal = async () => {
        setGroupName('');
        setCloudflareAccounts([]);
        setError('')
        onClose()
    }

    const handleSubmit = async () => {
        try {
            if (group) {
                await apiWrapper.put(`/api/cloudflare_groups/${group.id}`, {
                    name: groupName,
                    accounts: selectedAccounts,
                }, {
                    headers: {Authorization: token},
                });
            } else {
                await apiWrapper.post(`/api/cloudflare_groups`, {
                    name: groupName,
                    accounts: selectedAccounts,
                }, {
                    headers: {Authorization: token},
                });
            }
            onCloseModal()
        } catch (ex) {
            console.error("Failed add/update Cloudflare group", ex);
            setError(_.get(ex, "response.data.error", "Failed add/update Cloudflare group"));
        }
    };

    return (
        <Dialog open={open} onClose={onCloseModal} fullWidth maxWidth="sm">
            {error && <Typography color="error" align="center">{error}</Typography>}
            <DialogTitle>{group ? 'Редактировать группу' : 'Добавить группу'}</DialogTitle>
            <DialogContent>
                <TextField
                    label="Название группы"
                    value={groupName}
                    onChange={(e) => setGroupName(e.target.value)}
                    margin="normal"
                    fullWidth
                />
                <FormControl fullWidth margin="normal">
                    <InputLabel>Выберите Cloudflare аккаунты</InputLabel>
                    <Select
                        multiple
                        value={selectedAccounts}
                        onChange={(e) => setCloudflareAccounts(e.target.value)}
                        renderValue={(selected) => (
                            <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                {selected.map((id) => (
                                    <Chip key={id} label={accounts.find(acc => acc.id === id)?.name}/>
                                ))}
                            </Box>
                        )}
                    >
                        {accounts.map(account => (
                            <MenuItem key={account.id} value={account.id}>{account.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseModal} color="secondary">Отмена</Button>
                <Button onClick={handleSubmit} color="primary">Сохранить</Button>
            </DialogActions>
        </Dialog>
    );
}

export default CloudflareGroupsForm;