import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper, CircularProgress, Backdrop,
} from '@mui/material';
import apiWrapper from './../../apiConfig';
import Helper from './../../helper';
import DeletePopover from "../ui/DeletePopover";
import useAuthStore from "../../store/authStore";
import useErrorStore from "../../store/errorStore";

const PageRuleDialog = ({ open, onClose, domain }) => {
    const { token } = useAuthStore();
    const setErrorSnackbar = useErrorStore(state => state.setSnackbar);
    const [cloudflareAccounts, setCloudflareAccounts] = useState([]);
    const [pageRules, setPageRules] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState('');
    const [domainsToAttach, setDomainsToAttach] = useState('');
    const [isLoading, setIsLoading] = useState(false); // Новое состояние для лоадера

    useEffect(() => {
        const fetchAccountsAndPageRules = async () => {
            setIsLoading(true); // Начало загрузки
            try {
                const { data } = await apiWrapper.get(`/api/cloudflare_accounts`, {
                    headers: { Authorization: token },
                });
                setCloudflareAccounts(data.data || []);

                if(domain.cloudflare_account_name) {
                    const pageRulesResponse = await apiWrapper.get(`/api/domain_page_rules/${domain.id}`, {
                        headers: { Authorization: token },
                    });

                    setPageRules(pageRulesResponse?.data?.data?.result || []);
                }

            } catch (error) {
                console.error(error);
                setErrorSnackbar('Не удалось загрузить аккаунты или правила Cloudflare.', 'error');
            } finally {
                setIsLoading(false); // Окончание загрузки
            }
        };

        if (open) fetchAccountsAndPageRules();
        else resetState();
    }, [open]);

    const resetState = () => {
        setCloudflareAccounts([]);
        setPageRules([]);
        setSelectedAccount('');
        setDomainsToAttach('');
    };

    const handleDeleteRule = async (ruleId) => {
        try {
            await apiWrapper.delete(`/api/domain_page_rules/${domain.id}/${ruleId}`, {
                headers: { Authorization: token },
            });
            setPageRules((prevRules) => prevRules.filter(rule => rule.id !== ruleId));
            setErrorSnackbar('Правило успешно удалено!', 'success');
        } catch (error) {
            setErrorSnackbar('Ошибка при удалении правила.', 'error');
        }
    };

    const handleAttach = async () => {
        if (!selectedAccount || !domainsToAttach.trim() || !domain?.server_ip.trim()) {
            setErrorSnackbar('Пожалуйста, заполните все поля.', 'warning');
            return;
        }

        try {
            const domainsArray = domainsToAttach
                .split(/[\n,;]+/)
                .reduce((acc, domain) => {
                    const trimmed = domain.trim();
                    if (trimmed) {
                        acc.push(Helper.validateAndCleanUrl(trimmed));
                    }
                    return acc;
                }, []);

            if (!domainsArray.length) {
                setErrorSnackbar('Введите хотя бы один корректный домен.', 'warning');
                return;
            }

            const payload = {
                accountId: selectedAccount,
                domainName: domain?.domain_name,
                ipAddress: domain?.server_ip.trim(),
                pageRules: domainsArray,
            };

            const response = await apiWrapper.post(`/api/manage_dns`, payload, {
                headers: { Authorization: token },
            });

            setErrorSnackbar('Запрос успешно отправлен!', 'success');
            onClose();
        } catch (error) {
            console.error('Ошибка при отправке запроса:', error);
            setErrorSnackbar(error.message || 'Произошла ошибка при отправке данных.', 'error');
        }
    };

    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>

                <DialogTitle sx={{ pb: 0, fontSize: '1.25rem', fontWeight: 'bold' }}>
                    Подклейка домена
                </DialogTitle>
                <DialogContent sx={{ py: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                        <TextField
                            label="Домен к которому подклеивается"
                            value={domain?.domain_name || ''}
                            size="small"
                            fullWidth
                            disabled
                            sx={{ borderRadius: 1, flex: 1 }}
                        />
                        <TextField
                            label={domain?.server_name || ''}
                            value={domain?.server_ip || ''}
                            size="small"
                            fullWidth
                            disabled
                            sx={{ borderRadius: 1, flex: 1 }}
                        />
                    </Box>
                    <FormControl fullWidth size="small">
                        <InputLabel>Cloudflare Account</InputLabel>
                        <Select
                            value={selectedAccount}
                            onChange={(e) => setSelectedAccount(e.target.value)}
                            sx={{ borderRadius: 1 }}
                            variant={'outlined'}
                        >
                            {cloudflareAccounts.map((account) => (
                                <MenuItem key={account.id} value={account.id}>
                                    {account.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        label="Домены которые подклеиваются"
                        multiline
                        rows={4}
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={domainsToAttach}
                        onChange={(e) => setDomainsToAttach(e.target.value)}
                        sx={{ borderRadius: 1 }}
                    />
                    {pageRules.length > 0 && (
                        <>
                            <Box sx={{ mt: 2, mb: 1, fontWeight: 'bold', fontSize: '1rem' }}>
                                Действующие правила
                            </Box>
                            <TableContainer component={Paper}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Действие</TableCell>
                                            <TableCell>Откуда</TableCell>
                                            <TableCell>Куда</TableCell>
                                            <TableCell>Статус</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {pageRules.map((rule) => (
                                            <TableRow key={rule.id}>
                                                <TableCell>
                                                    {rule.actions.map((action) => {
                                                        switch (action.id) {
                                                            case 'forwarding_url':
                                                                return 'Редирект';
                                                            case 'security_level':
                                                                return 'Изменение уровня безопасности';
                                                            default:
                                                                return action.id;
                                                        }
                                                    }).join(', ')}
                                                </TableCell>
                                                <TableCell>
                                                    {rule.targets.map((target) => target.constraint.value).join(', ')}
                                                </TableCell>
                                                <TableCell>
                                                    {rule.actions.map((action) => {
                                                        if (action.id === 'forwarding_url') {
                                                            return action.value?.url || '—';
                                                        }
                                                        return '—';
                                                    }).join(', ')}
                                                </TableCell>
                                                <TableCell>
                                                    <Box
                                                        sx={{
                                                            width: 12,
                                                            height: 12,
                                                            borderRadius: '50%',
                                                            bgcolor: rule.status === 'active' ? 'green' : 'red',
                                                            display: 'inline-block',
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <DeletePopover onDelete={() => handleDeleteRule(rule.id)} />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    )}
                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                    <Button onClick={onClose} color="secondary" variant="outlined" sx={{ mr: 1 }}>
                        Отмена
                    </Button>
                    <Button onClick={handleAttach} color="primary" variant="contained">
                        Подклеить
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default PageRuleDialog;
