import React, { useState } from 'react';
import axios from 'axios';
import { yellow, grey } from '@mui/material/colors';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Typography,
    Box,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Switch,
    FormControlLabel, IconButton,
    InputAdornment, Tooltip
} from '@mui/material';
import { BugReport } from '@mui/icons-material';
import Helper from "../../helper";
import useErrorStore from "../../store/errorStore";
import useAuthStore from "../../store/authStore";

const AutoBuyDialog = ({
   open,
   onClose,
   projectId = null,
}) => {
    const { token } = useAuthStore();

    const defaultTemplate = 'site-{casino|key|word}-{1|2|3|4|5|6|7|8|9|0}.{ru|com}';
    const setSnackbar = useErrorStore(state => state.setSnackbar);

    const [domainTemplate, setDomainTemplate] = useState(defaultTemplate);
    const [registrar, setRegistrar] = useState('');
    const [cloudflareGroup, setCloudflareGroup] = useState('');
    const [autoRegistration, setAutoRegistration] = useState(true);
    const [registrars, setRegistrars] = useState([]);
    const [groups, setGroups] = useState([]);

    // Загрузка данных при открытии модального окна
    React.useEffect(() => {
        const fetchData = async () => {
            if (open && projectId) {
                try {
                    const headers = {Authorization: token};

                    const autoBuyResponse = await axios.get(`/api/auto_buy/${projectId}`, {headers});
                    const autoBuyData = autoBuyResponse.data;

                    const registrarsResponse = await axios.get('/api/registrars', {headers});
                    const registrarsData = registrarsResponse.data.data.filter(registrar => registrar.type === 'reghouse.ru' || registrar.type === 'namecheap.com');


                    const groupsResponse = await axios.get('/api/cloudflare_groups', {headers});
                    const groupsData = groupsResponse.data;

                    // Установка полученных данных
                    setDomainTemplate(autoBuyData.template || defaultTemplate);
                    setRegistrar(autoBuyData.registrars_id || '');
                    setCloudflareGroup(autoBuyData.cloudflare_groups_id || '');
                    setAutoRegistration(autoBuyData.state === 'on');
                    setRegistrars(registrarsData);
                    setGroups(groupsData);
                } catch (error) {
                    console.error(error);
                    setSnackbar('Ошибка при загрузке данных', 'error');
                }
            }
        };

        fetchData();
    }, [open, projectId]);

    // Очистка данных при закрытии
    const handleClose = () => {
        onClose();
        setDomainTemplate(defaultTemplate);
        setRegistrar('');
        setCloudflareGroup('');
        setAutoRegistration(true);
    };

    const onSubmit = async () => {
        try {
            const headers = {Authorization: token};
            const payload = {
                template: domainTemplate,
                registrars_id: registrar,
                project_id: projectId,
                cloudflare_groups_id: cloudflareGroup,
                state: autoRegistration ? 'on' : 'off'
            };

            const response = await axios.post(`/api/auto_buy/`, payload, {headers});

            if (response.status === 200) {
                setSnackbar('Данные успешно сохранены!', 'success');
                handleClose();
            } else {
                setSnackbar('Ошибка при сохранении данных', 'error');
            }
        } catch (error) {
            console.error(error);
            setSnackbar('Произошла ошибка при сохранении данных', 'error');
        }
    };


    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle sx={{
                pb: 0.5,
            }}>
                Автоматическая регистрация доменов
            </DialogTitle>
            <DialogContent sx={{ mt: 1 }}>
                <Box
                    sx={{
                        p: 2,
                        pt: 1,
                        mb: 3,
                        bgcolor: yellow[300],
                        color: grey[900],
                        borderRadius: 1,
                    }}
                >
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
                        Памятка по шаблону
                    </Typography>
                    <Typography variant="body2">
                        &#123;текст 1|текст 2|текст 3&#125; – перебор<br/>
                        [текст 1|текст 2|текст 3] – перестановки<br/>
                        [ +разделитель+текст 1|текст 2|текст 3 ] – перестановки с разделителем<br/>
                    </Typography>
                </Box>

                {/* Поле для "Шаблона домена" */}
                <TextField
                    label="Шаблон домена"
                    placeholder="Введите шаблон домена..."
                    multiline
                    fullWidth
                    minRows={3}
                    value={domainTemplate}
                    onChange={(e) => setDomainTemplate(e.target.value)}
                    sx={{ mb: 2 }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Tooltip title="Проверить">
                                    <IconButton size="small" onClick={(e) => {
                                        e.preventDefault();
                                        let validation = Helper.validateTemplate(domainTemplate);
                                        if(!validation.isValid) {
                                            setSnackbar(validation.errors.join('\n'), 'error');
                                        } else {
                                            for (let i = 0; i < 5; i++) {
                                                let generatedDomains = Helper.generateDomainsFromTemplate(domainTemplate);
                                                setSnackbar(generatedDomains);
                                            }
                                        }
                                        if (validation.warnings.length > 0) {
                                            setSnackbar(validation.warnings.join('\n'), 'warning');
                                        }
                                    }}>
                                        <BugReport fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </InputAdornment>
                        )
                    }}
                />

                <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel id="registrar-label">Регистратор</InputLabel>
                    <Select
                        labelId="registrar-label"
                        label="Регистратор"
                        value={registrar}
                        onChange={(e) => setRegistrar(e.target.value)}
                        variant={"outlined"}
                    >
                        {registrars.map((registrar) => (
                            <MenuItem key={registrar.id} value={registrar.id}>
                                {registrar.name} ({registrar.type})
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel id="cloudflare-group-label">Cloudflare</InputLabel>
                    <Select
                        labelId="cloudflare-group-label"
                        label="Cloudflare"
                        value={cloudflareGroup}
                        onChange={(e) => setCloudflareGroup(e.target.value)}
                        variant={"outlined"}
                    >
                        {groups.map((group) => (
                            <MenuItem key={group.id} value={group.id}>
                                {group.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControlLabel
                    control={
                        <Switch
                            checked={autoRegistration}
                            onChange={(e) => setAutoRegistration(e.target.checked)}
                            color="primary"
                        />
                    }
                    label="Включить авторегистрацию"
                />
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose}>Отмена</Button>
                <Button onClick={onSubmit} variant="contained">
                    Сохранить
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AutoBuyDialog;