import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ThemeContextProvider } from './components/context/ThemeContext';
import {SnackbarProvider} from "notistack";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ThemeContextProvider>
            <SnackbarProvider
                maxSnack={5}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                dense
            >
                <App />
            </SnackbarProvider>
        </ThemeContextProvider>
    </React.StrictMode>
);