import React, { useState, useEffect } from 'react';
import { Box, Tab, Tabs, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, IconButton, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import useErrorStore from "../../../store/errorStore";
import useAuthStore from "../../../store/authStore";
import apiWrapper from "../../../apiConfig";

const API_TYPE_YANDEX = 'yandex';
const API_TYPE_GOOGLE = 'google';

const TYPES = {
    [API_TYPE_YANDEX]: 0,
    [API_TYPE_GOOGLE]: 1
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{ width: '100%' }} // Гарантируем полную ширину
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1, width: '100%' }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const PositionDataComponent = ({ projectId, keyword }) => {
    const { token } = useAuthStore();
    const [type, setType] = useState(TYPES[API_TYPE_YANDEX]);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [fetchingPositions, setFetchingPositions] = useState(false);

    const setSnackbar = useErrorStore(state => state.setSnackbar);

    const fetchData = async () => {
        try {
            setLoading(true);
            const currentTypeKey = Object.keys(TYPES).find(key => TYPES[key] === type);
            const response = await apiWrapper.get(`/api/position/${projectId}/${currentTypeKey}/${keyword.id}`, {
                headers: { Authorization: token }
            });
            setData(response.data);
            setLoading(false);
        } catch (err) {
            setSnackbar('Произошла ошибка при загрузке данных', 'error');
            console.error('Ошибка загрузки данных:', err);
        }
    };

    useEffect(() => {
        fetchData();
    }, [projectId, type]);

    const handleChange = (event, newValue) => {
        setType(newValue);
    };

    const handleFetchPositions = async () => {
        try {
            setFetchingPositions(true);
            const currentTypeKey = Object.keys(TYPES).find(key => TYPES[key] === type);

            await apiWrapper.post('/api/fetch-positions', {
                projectId,
                keywordId: keyword.id,
                type: currentTypeKey
            }, {
                headers: { Authorization: token }
            });

            setSnackbar('Запрос на получение позиций отправлен. Обновление данных может занять некоторое время.', 'success');

            // Refresh data after a short delay to allow backend processing
            setTimeout(() => {
                fetchData();
                setFetchingPositions(false);
            }, 3000);
        } catch (err) {
            setSnackbar('Произошла ошибка при запросе позиций', 'error');
            setFetchingPositions(false);
            console.error('Ошибка запроса позиций:', err);
        }
    };

    const renderTable = (source) => {
        if (!data || !data[source]) return null;

        const { dates, domains, values } = data[source];

        return (
            <TableContainer
                component={Paper}
                sx={{
                    overflowX: 'auto',
                    maxWidth: '1100px',
                }}

            >
                <Table
                    aria-label={`${source} data table`}
                    stickyHeader
                    sx={{
                        width: 'max-content',
                        minWidth: '100%',
                    }}

                >
                    <TableHead>
                        <TableRow>
                            <TableCell
                                sx={{
                                    position: 'sticky',
                                    left: 0,
                                    zIndex: 3,
                                    backgroundColor: 'background.paper',
                                    borderRight: '1px solid rgba(224, 224, 224, 1)',
                                    minWidth: '180px',
                                    width: '180px'
                                }}
                            >
                                Домены
                            </TableCell>
                            {dates.map((date, index) => (
                                <TableCell
                                    key={index}
                                    align="center"
                                    sx={{
                                        width: '90px',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    {date}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {domains.map((domain, domainIndex) => (
                            <TableRow
                                key={domainIndex}
                                hover
                                sx={{
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                    },
                                }}
                            >
                                <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                        position: 'sticky',
                                        left: 0,
                                        backgroundColor: (theme) => theme.palette.background.paper,
                                        borderRight: '1px solid rgba(224, 224, 224, 1)',
                                        zIndex: 5,
                                        minWidth: '180px',
                                        width: '180px',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}
                                >
                                    {domain}
                                </TableCell>
                                {dates.map((_, dateIndex) => (
                                    <TableCell
                                        key={dateIndex}
                                        align="center"
                                        sx={{
                                            width: '90px',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        {values[dateIndex]?.[domainIndex] || '-'}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    return (
        <Box sx={{ width: '100%', overflow: 'hidden' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', alignItems: 'center' }}>
                <Tabs 
                    value={type} 
                    onChange={handleChange} 
                    aria-label="API type tabs"
                    sx={{ flexGrow: 1 }}
                >
                    <Tab label="Яндекс" {...a11yProps(TYPES[API_TYPE_YANDEX])} />
                    <Tab label="Google" {...a11yProps(TYPES[API_TYPE_GOOGLE])} />
                </Tabs>
                <Tooltip title="Снять позиции">
                    <IconButton 
                        onClick={handleFetchPositions} 
                        disabled={fetchingPositions}
                        color="primary"
                        sx={{ mr: 1 }}
                    >
                        <RefreshIcon />
                    </IconButton>
                </Tooltip>
            </Box>

            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <TabPanel value={type} index={TYPES[API_TYPE_YANDEX]}>
                        {renderTable(API_TYPE_YANDEX)}
                    </TabPanel>
                    <TabPanel value={type} index={TYPES[API_TYPE_GOOGLE]}>
                        {renderTable(API_TYPE_GOOGLE)}
                    </TabPanel>
                </>
            )}
        </Box>
    );
};

export default PositionDataComponent;
