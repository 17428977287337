import React, { useState, useEffect } from 'react';
import { 
    Box, 
    Tab, 
    Tabs, 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    Paper, 
    CircularProgress, 
    Dialog, 
    DialogTitle, 
    DialogContent, 
    IconButton,
    Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useErrorStore from "../../../store/errorStore";
import useAuthStore from "../../../store/authStore";
import apiWrapper from "../../../apiConfig";

const API_TYPE_YANDEX = 'yandex';
const API_TYPE_GOOGLE = 'google';

const TYPES = {
    [API_TYPE_YANDEX]: 0,
    [API_TYPE_GOOGLE]: 1
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{ width: '100%' }}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1, width: '100%' }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const KeywordPositionDialog = ({ open, onClose, domainId, domainName }) => {
    const { token } = useAuthStore();
    const [type, setType] = useState(TYPES[API_TYPE_YANDEX]);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    const setSnackbar = useErrorStore(state => state.setSnackbar);

    const fetchData = async () => {
        try {
            setLoading(true);
            const currentTypeKey = Object.keys(TYPES).find(key => TYPES[key] === type);
            const response = await apiWrapper.get(`/api/position/${domainId}/${currentTypeKey}`, {
                headers: { Authorization: token }
            });
            setData(response.data);
            setLoading(false);
        } catch (err) {
            setSnackbar('Произошла ошибка при загрузке данных', 'error');
            console.error('Ошибка загрузки данных:', err);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (open && domainId) {
            fetchData();
        }
    }, [domainId, type, open]);

    const handleChange = (event, newValue) => {
        setType(newValue);
    };

    const renderTable = (source) => {
        if (!data || !data[source]) return null;

        const { dates, keywords, values } = data[source];

        return (
            <TableContainer
                component={Paper}
                sx={{
                    overflowX: 'auto',
                    maxWidth: '1100px',
                }}
            >
                <Table
                    aria-label={`${source} data table`}
                    stickyHeader
                    sx={{
                        width: 'max-content',
                        minWidth: '100%',
                    }}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell
                                sx={{
                                    position: 'sticky',
                                    left: 0,
                                    zIndex: 3,
                                    backgroundColor: 'background.paper',
                                    borderRight: '1px solid rgba(224, 224, 224, 1)',
                                    minWidth: '180px',
                                    width: '180px'
                                }}
                            >
                                Ключевые слова
                            </TableCell>
                            {dates.map((date, index) => (
                                <TableCell
                                    key={index}
                                    align="center"
                                    sx={{
                                        width: '90px',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    {date}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {keywords.map((keyword, keywordIndex) => (
                            <TableRow
                                key={keywordIndex}
                                hover
                                sx={{
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                    },
                                }}
                            >
                                <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                        position: 'sticky',
                                        left: 0,
                                        backgroundColor: (theme) => theme.palette.background.paper,
                                        borderRight: '1px solid rgba(224, 224, 224, 1)',
                                        zIndex: 5,
                                        minWidth: '180px',
                                        width: '180px',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}
                                >
                                    {keyword}
                                </TableCell>
                                {dates.map((_, dateIndex) => (
                                    <TableCell
                                        key={dateIndex}
                                        align="center"
                                        sx={{
                                            width: '90px',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        {values[dateIndex]?.[keywordIndex] || '-'}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="lg"
            fullWidth
        >
            <DialogTitle>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h6">
                        Позиции ключевых слов для домена: {domainName}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ width: '100%', overflow: 'hidden' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs 
                            value={type} 
                            onChange={handleChange} 
                            aria-label="API type tabs"
                        >
                            <Tab label="Яндекс" {...a11yProps(TYPES[API_TYPE_YANDEX])} />
                            <Tab label="Google" {...a11yProps(TYPES[API_TYPE_GOOGLE])} />
                        </Tabs>
                    </Box>

                    {loading ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <>
                            <TabPanel value={type} index={TYPES[API_TYPE_YANDEX]}>
                                {renderTable(API_TYPE_YANDEX)}
                            </TabPanel>
                            <TabPanel value={type} index={TYPES[API_TYPE_GOOGLE]}>
                                {renderTable(API_TYPE_GOOGLE)}
                            </TabPanel>
                        </>
                    )}
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default KeywordPositionDialog;