import React, {useState, useEffect} from 'react';
import {
    Container,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
    IconButton,
    Typography,
    Box,
    Chip
} from '@mui/material';
import {Edit} from '@mui/icons-material';
import DeletePopover from '../../ui/DeletePopover';
import apiWrapper from '../../../apiConfig';
import CloudflareGroupsForm from "./CloudflareGroupsForm";
import useAuthStore from "../../../store/authStore";

const CloudflareGroups = () => {
    const { token } = useAuthStore();
    const [groups, setGroups] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [editingGroup, setEditingGroup] = useState(null);
    const [error, setError] = useState('');

    useEffect(() => {
        fetchGroups();
    }, []);

    const fetchGroups = async () => {
        try {
            const response = await apiWrapper.get(`/api/cloudflare_groups`, {
                headers: {Authorization: token},
            });
            const {data} = response;
            setGroups(data);
        } catch (error) {
            setError('Не удалось загрузить список Cloudflare групп');
        }
    };

    const handleOpenDialog = (group = null) => {
        setEditingGroup(group);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setEditingGroup(null);
        fetchGroups()
    };

    const handleDeleteGroup = async (groupId) => {
        try {
            await apiWrapper.delete(`/api/cloudflare_groups/${groupId}`, {
                headers: {Authorization: token}
            });
            fetchGroups();
        } catch (error) {
            console.error("Ошибка удаления группы:", error);
        }
    };

    return (
        <Container>
            {error && <Typography color="error" align="center">{error}</Typography>}
            <Box display="flex" justifyContent="flex-end" marginBottom={2}>
                <Button variant="contained" color="primary" onClick={() => handleOpenDialog()}>Создать группу</Button>
            </Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Название</TableCell>
                        <TableCell>Cloudflare Аккаунты</TableCell>
                        <TableCell>Действия</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {groups.map((group) => (
                        <TableRow key={group.id}>
                            <TableCell>{group.name}</TableCell>
                            <TableCell>
                                {group.accounts.length > 0 ? (
                                    group.accounts.map(acc => (
                                        <Chip key={acc.id} label={acc.name} style={{margin: '2px'}}/>
                                    ))
                                ) : (
                                    'Нет аккаунтов'
                                )}
                            </TableCell>
                            <TableCell>
                                <IconButton color="primary" onClick={() => handleOpenDialog(group)}>
                                    <Edit/>
                                </IconButton>
                                <DeletePopover onDelete={() => handleDeleteGroup(group.id)} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <CloudflareGroupsForm
                open={openDialog}
                onClose={handleCloseDialog}
                group={editingGroup}
            />
        </Container>
    );
}
export default CloudflareGroups;
