import React, { useEffect, useState } from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
} from "@mui/material";
import apiWrapper from "../../apiConfig";
import useAuthStore from "../../store/authStore";

const RegistrarDomainListDialog = ({ open, registrarId, onClose }) => {
    const { token } = useAuthStore();
    const [domains, setDomains] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (open && registrarId) {
            setLoading(true);
            apiWrapper
                .get(`/api/registrars/${registrarId}`, {
                    headers: { Authorization: token },
                })
                .then(({ data }) => {
                    setDomains(data.domains);
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });
        } else {
            setDomains([]);
        }
    }, [open, registrarId, token]);

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>Список доменов</DialogTitle>
            <DialogContent>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Домен</TableCell>
                                <TableCell>Дата истечения</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {domains.map((domain, key) => (
                                <TableRow key={key}>
                                    <TableCell>{domain.domain}</TableCell>
                                    <TableCell>{domain.expiredAt}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Закрыть</Button>
            </DialogActions>
        </Dialog>
    );
};

export default RegistrarDomainListDialog;