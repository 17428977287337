import React from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Button,
} from "@mui/material";
import apiWrapper from "../../apiConfig";
import useAuthStore from "../../store/authStore";
import useErrorStore from "../../store/errorStore";

const XmlRiverDialog = ({ open, isEditing, data, onClose, fetchData }) => {
    const { token } = useAuthStore();
    const setSnackbar = useErrorStore(state => state.setSnackbar);
    const [formData, setFormData] = React.useState(data);

    React.useEffect(() => {
        setFormData(data);
    }, [data]);

    const handleChange = (field, value) => {
        setFormData((prev) => ({ ...prev, [field]: value }));
    };

    const handleSubmit = async () => {
        try {
            if (isEditing) {
                await apiWrapper.put(`/api/xmlriver/${formData.id}`, formData, {
                    headers: { Authorization: token },
                });
            } else {
                await apiWrapper.post(`/api/xmlriver`, formData, {
                    headers: { Authorization: token },
                });
            }
            fetchData();
            setFormData({
                name: "",
                login: "",
                key: "",
            })
            onClose();
        } catch (e) {
            console.error(e);
            setSnackbar("Ошибка сохранения", "error");
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{isEditing ? "Редактировать" : "Добавить"}</DialogTitle>
            <DialogContent>
                <DialogContentText>Заполните данные:</DialogContentText>
                <TextField
                    fullWidth
                    margin="normal"
                    label="Имя"
                    value={formData.name}
                    onChange={(e) => handleChange("name", e.target.value)}
                />
                <TextField
                    fullWidth
                    margin="normal"
                    label="user_id"
                    value={formData.login}
                    onChange={(e) => handleChange("login", e.target.value)}
                />
                <TextField
                    fullWidth
                    margin="normal"
                    label="key"
                    value={formData.key}
                    onChange={(e) => handleChange("key", e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Отмена</Button>
                <Button onClick={handleSubmit} color="primary">
                    {isEditing ? "Сохранить" : "Добавить"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default XmlRiverDialog;