import React, { useEffect, useState } from 'react';
import {
  Container, Typography, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  TextField, Grid, IconButton, Card, CardContent, CardActions, Divider, Input, Link
} from '@mui/material';
import { Edit, GetApp } from '@mui/icons-material';
import DeletePopover from '../../ui/DeletePopover';
import apiWrapper from '../../../apiConfig';
import useAuthStore from "../../../store/authStore";
import useErrorStore from "../../../store/errorStore";

const Templates = () => {
  const { token } = useAuthStore();
  const setError = useErrorStore(state => state.setSnackbar);
  const [templates, setTemplates] = useState([]);
  const [newTemplateName, setNewTemplateName] = useState('');
  const [newTemplateFile, setNewTemplateFile] = useState(null);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editingTemplate, setEditingTemplate] = useState(null);

  useEffect(() => {
    fetchTemplates();
  }, [token]);

  const fetchTemplates = async () => {
    try {
      const response = await apiWrapper.get(`/api/templates`, {
        headers: { Authorization: token }
      });
      setTemplates(response.data.data);
    } catch (error) {
      setError('Failed to fetch templates', 'error');
    }
  };

  const handleOpenAddDialog = () => {
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
    setNewTemplateName('');
    setNewTemplateFile(null);
  };

  const handleAddTemplate = async () => {
    if (!newTemplateName) {
      setError('Template name is required', 'error');
      return;
    }

    if (!newTemplateFile) {
      setError('Template file is required', 'error');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('name', newTemplateName);
      formData.append('template_file', newTemplateFile);

      await apiWrapper.post(`/api/templates`, formData, {
        headers: { 
          Authorization: token,
          'Content-Type': 'multipart/form-data'
        }
      });

      fetchTemplates();
      handleCloseAddDialog();
    } catch (error) {
      setError('Failed to add template', 'error');
    }
  };

  const handleOpenEditDialog = (template) => {
    setEditingTemplate(template);
    setNewTemplateName(template.name);
    setNewTemplateFile(null);
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setEditingTemplate(null);
    setNewTemplateName('');
    setNewTemplateFile(null);
  };

  const handleEditTemplate = async () => {
    if (!newTemplateName) {
      setError('Template name is required', 'error');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('name', newTemplateName);

      if (newTemplateFile) {
        formData.append('template_file', newTemplateFile);
      }

      await apiWrapper.post(`/api/templates/${editingTemplate.id}`, formData, {
        headers: { 
          Authorization: token,
          'Content-Type': 'multipart/form-data'
        }
      });

      fetchTemplates();
      handleCloseEditDialog();
    } catch (error) {
      setError('Failed to edit template', 'error');
    }
  };

  const handleDeleteTemplate = async (templateId) => {
    try {
      await apiWrapper.delete(`/api/templates/${templateId}`, {
        headers: { Authorization: token }
      });
      fetchTemplates();
    } catch (error) {
      setError('Failed to delete template', 'error');
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type !== 'application/zip' && !file.name.endsWith('.zip')) {
      setError('Only ZIP files are allowed', 'error');
      return;
    }
    setNewTemplateFile(file);
  };

  const getFileName = (path) => {
    if (!path) return '';
    return path.split('/').pop();
  };

  const handleDownloadTemplate = (template) => {
    try {
      // Get the file path from the template
      const filePath = template.file_path;
      if (!filePath) {
        setError('Template file path not found', 'error');
        return;
      }

      // Create a URL for the file
      const fileName = getFileName(filePath);

      // Create a temporary link element
      const link = document.createElement('a');

      // Set the href to the file path
      // This assumes the server is configured to serve static files from the templates directory
      link.href = filePath;

      // Set the download attribute to force download instead of navigation
      link.setAttribute('download', fileName);

      // Open in a new tab as a fallback
      link.setAttribute('target', '_blank');

      // Append to the document, click it, and remove it
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Download error:', error);
      setError('Failed to download template', 'error');
    }
  };

  return (
    <Container>
      <Box display="flex" justifyContent="flex-end" marginBottom={2}>
        <Button variant="contained" color="primary" onClick={handleOpenAddDialog}>
          Добавить шаблон
        </Button>
      </Box>

      {/* Display templates in a grid of cards */}
      <Grid container spacing={2}>
        {templates.length === 0 && (
            <Typography variant="h6" gutterBottom>
              Нету шаблонов
            </Typography>
        )}
        {templates.map((template) => (
          <Grid item xs={12} sm={6} md={4} key={template.id}>
            <Card sx={{ maxWidth: 345 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {template.name}
                </Typography>
                <Typography 
                  variant="body2" 
                  color="textSecondary" 
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <strong>Файл:</strong> 
                  <Link 
                    component="button"
                    variant="body2"
                    onClick={(e) => {
                      e.preventDefault();
                      handleDownloadTemplate(template);
                    }}
                    style={{ marginLeft: '5px', cursor: 'pointer' }}
                  >
                    {getFileName(template.file_path)}
                  </Link>
                </Typography>
              </CardContent>
              <Divider />
              <CardActions>
                <IconButton color="primary" onClick={() => handleDownloadTemplate(template)}>
                  <GetApp />
                </IconButton>
                <IconButton color="primary" onClick={() => handleOpenEditDialog(template)}>
                  <Edit />
                </IconButton>
                <DeletePopover onDelete={() => handleDeleteTemplate(template.id)} />
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Add Template Dialog */}
      <Dialog open={openAddDialog} onClose={handleCloseAddDialog}>
        <DialogTitle>Add Template</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the name and upload a ZIP file for the new template.
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Template Name"
                value={newTemplateName}
                onChange={(e) => setNewTemplateName(e.target.value)}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                type="file"
                onChange={handleFileChange}
                fullWidth
                inputProps={{ accept: '.zip,application/zip' }}
              />
              <Typography variant="caption" color="textSecondary">
                Only ZIP files are allowed
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddTemplate} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Template Dialog */}
      <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
        <DialogTitle>Edit Template</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Update the name and optionally upload a new ZIP file for the template.
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Template Name"
                value={newTemplateName}
                onChange={(e) => setNewTemplateName(e.target.value)}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                type="file"
                onChange={handleFileChange}
                fullWidth
                inputProps={{ accept: '.zip,application/zip' }}
              />
              <Typography variant="caption" color="textSecondary">
                Only ZIP files are allowed. Leave empty to keep the current file.
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleEditTemplate} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Templates;
