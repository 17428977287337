import React, { useCallback, useEffect, useState } from "react";
import {
    Box,
    Button,
    Container,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { Edit, List } from "@mui/icons-material";
import DeletePopover from "../../ui/DeletePopover";
import apiWrapper from "../../../apiConfig";
import XmlRiverDialog from "../../dialogs/XmlRiverDialog";
import useAuthStore from "../../../store/authStore";
import useErrorStore from "../../../store/errorStore";

const initialState = {
    name: "",
    login: "",
    key: "",
};

const XmlRiver = () => {
    const { token } = useAuthStore();
    const [data, setData] = useState([]);
    const setError = useErrorStore(state => state.setSnackbar);
    const [dialogState, setDialogState] = useState({
        open: false,
        isEditing: false,
        formData: initialState,
    });

    const fetchData = useCallback(async () => {
        try {
            const { data } = await apiWrapper.get(`/api/xmlriver`, {
                headers: { Authorization: token },
            });
            setData(data);
        } catch {
            setError("Не удалось загрузить список регистраторов", "error");
        }
    }, [token]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleXmlRiverDialogOpen = (isEditing = false, data = initialState) => {
        setDialogState({ open: true, isEditing, formData: data });
    };

    const handleXmlRiverDialogClose = () => {
        setDialogState({ open: false, isEditing: false, formData: initialState });
    };

    const handleDelete = async (id) => {
        try {
            await apiWrapper.delete(`/api/xmlriver/${id}`, {
                headers: { Authorization: token },
            });
            fetchData();
        } catch {
            setError("Не удалось удалить регистратора", "error");
        }
    };

    return (
        <Container>
            <Box display="flex" justifyContent="flex-end" mb={2}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleXmlRiverDialogOpen()}
                >
                    Добавить
                </Button>
            </Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Имя</TableCell>
                        <TableCell>Логин</TableCell>
                        <TableCell>Пароль</TableCell>
                        <TableCell>Баланс</TableCell>
                        <TableCell>Действия</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data && data.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.login}</TableCell>
                            <TableCell>{row.key}</TableCell>
                            <TableCell>{row.balance}</TableCell>
                            <TableCell>
                                <IconButton
                                    color="primary"
                                    onClick={() => handleXmlRiverDialogOpen(true, row)}
                                >
                                    <Edit />
                                </IconButton>
                                <DeletePopover onDelete={() => handleDelete(row.id)} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <XmlRiverDialog
                open={dialogState.open}
                isEditing={dialogState.isEditing}
                data={dialogState.formData}
                onClose={handleXmlRiverDialogClose}
                fetchData={fetchData}
            />
        </Container>
    );
};

export default XmlRiver;
