import React, { useState, useEffect } from 'react';
import {
    Container,
    Typography,
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableRow,
} from '@mui/material';
import apiWrapper from '../../../apiConfig';
import useAuthStore from "../../../store/authStore";
import useErrorStore from "../../../store/errorStore";
import PositionData from "./PositionData";

const Position = () => {
    const { token } = useAuthStore();

    const setSnackbar = useErrorStore(state => state.setSnackbar);

    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);

    const fetchProjects = async () => {
        try {
            const response = await apiWrapper.get(`/api/user_projects`, {
                headers: { Authorization: token }
            });
            setProjects(response.data.data);
        } catch (err) {
            setSnackbar('Failed to fetch projects', 'error');
        }
    };

    useEffect(() => {
        fetchProjects();
    }, []);

    const handleSelectProject = (project) => {
        setSelectedProject(project);
    };

    return (
        <Container maxWidth="xl">
            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} gap={2}>
                <Box
                    width={{ xs: '100%', md: '20%' }}
                    marginBottom={{ xs: 2, md: 0 }}
                    sx={{ overflowY: 'auto', maxHeight: '80vh' }}
                >
                    <Paper sx={{ overflowX: 'auto' }}>
                        <Table>
                            <TableBody>
                                {projects.map((project) => (
                                    <TableRow
                                        key={project.id}
                                        sx={{ cursor: 'pointer' }}
                                        onClick={() => handleSelectProject(project)}
                                        selected={selectedProject?.id === project.id}
                                    >
                                        <TableCell>
                                            {project.name}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>
                </Box>

                <Box flex={1} overflow="hidden" sx={{ display: 'flex', flexDirection: 'column' }}>
                    {selectedProject ? (
                        <PositionData projectId={selectedProject.id} />
                    ) : (
                        <Typography variant="h6" color="text.secondary" sx={{ textAlign: 'center' }}>
                            Выберите проект...
                        </Typography>
                    )}
                </Box>
            </Box>
        </Container>
    );
};

export default Position;