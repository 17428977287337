import { create } from 'zustand';
import jwtDecode from 'jwt-decode';

const useAuthStore = create((set) => ({
    initializeAuth: () => {
        const storedToken = localStorage.getItem('token');
        if (storedToken) {
            set({
                token: storedToken,
                user: jwtDecode(storedToken)
            });
        }
    },
    token: localStorage.getItem('token') || '',
    user: localStorage.getItem('token') ? jwtDecode(localStorage.getItem('token')) : {},
    setToken: (newToken) => {
        if (newToken) {
            localStorage.setItem('token', newToken);
            set({
                token: newToken,
                user: jwtDecode(newToken)
            });
        } else {
            localStorage.removeItem('token');
            set({
                token: '',
                user: {}
            });
        }
    },
}));

export default useAuthStore;